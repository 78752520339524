import React, { useState, useEffect, useRef } from "react";
import "./profile.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BiSolidEditAlt } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";

export default function UpdateProfile() {
  const [read, setRead] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const role = localStorage.getItem("role");
  const mytoken = localStorage.getItem("token");
  const filePicker = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    twitterURL: "",
    linkedin: "",
    bio: "",
    userProfileImage: "",
    password: "",
  });
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(`${apiUrl}user/${id}`).then((response) => {
      const data = response.data.data;
      setFormData({
        name: data.name || "",
        email: data.email || "",
        role: data.role || "",
        twitterURL: data.twitterUrl || "",
        linkedin: data.linkedinUrl || "",
        bio: data.bio || "",
        password: "", // Passwords are usually not fetched for security reasons
        userProfileImage: data.userProfileImage || "",
      });
      setBannerImagePreview(data.userProfileImage);
    });
  }, [apiUrl, id]);

  const onBannerImagePicked = (event) => {
    const file = event.target.files[0];
    handleFileSelection(
      file,
      setBannerImageFile,
      setBannerImagePreview,
      setBannerFileSizeError
    );
  };

  const handleFileSelection = (file, setFile, setPreview, setError) => {
    const maxSizeInKB = 5000; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        alert("File size exceeds the maximum allowed size.");
        setError(true);
        setFile(null);
        if (setPreview) setPreview("");
      } else {
        setFile(file);
        if (setPreview) {
          const reader = new FileReader();
          reader.onload = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setError(false);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const payload = {
      userId: id,
      email: formData.email,
      name: formData.name,
      bio: formData.bio,
      twitterUrl: formData.twitterURL,
      linkedinUrl: formData.linkedin,
    };
    if (bannerImagePreview) {
      payload.userProfileImage = bannerImagePreview;
    }
    if (oldPassword && newPassword) {
      payload.oldPassword = oldPassword;
      payload.newPassword = newPassword;
    }
    try {
      const headers = { Authorization: `Bearer ${mytoken}` };
      const response = await axios.put(`${apiUrl}user/updateUser`, payload, {
        headers,
      });
      if (response) {
        toast.success("Profile Updated Successfully !");
        setRead(true);
      }
      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    // Regular expression for password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Perform validation
    if (!newPassword.trim()) {
      setPasswordError("Password is required");
    } else if (!passwordRegex.test(newPassword)) {
      setPasswordError(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, and be at least 8 characters long! Example: Abcd@1234"
      );
    } else {
      setPasswordError(""); // Clear error if password is valid
    }
  };
  const handleOldPasswordChange = (e) => {
    const newPassword = e.target.value;
    setOldPassword(newPassword);

    // Regular expression for password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Perform validation
    if (!newPassword.trim()) {
      setPasswordError("Password is required");
    } else if (!passwordRegex.test(newPassword)) {
      setPasswordError(
        "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, and be at least 8 characters long! Example: Abcd@1234"
      );
    } else {
      setPasswordError(""); // Clear error if password is valid
    }
  };

  console.log(bannerImagePreview, "bannerImagePreview");
  return (
    <>
      <div className="line"></div>
      <div className="card">
        <div className="card-header">Update Profile</div>
        <div className="card-body">
          <div className="update-container">
            <div className="update-img-container">
              <div className="img-container">
                <img
                  src={bannerImagePreview || formData.userProfileImage}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                id="bannerImage"
                accept="image/*"
                onChange={onBannerImagePicked}
              />
              {bannerFileSizeError && (
                <div className="error-message">
                  File size exceeds the maximum allowed size.
                </div>
              )}
            </div>
            <div className="profile-details">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="John Doe"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="abc@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onClick={() => {
                    if (role === "admin") {
                      alert(
                        "Contact Your SuperAdmin To Change Your Email or Password !"
                      );
                    }
                  }}
                  readOnly={role === "superadmin" ? read : true}
                />
              </div>

              <div className="form-group">
                <input
                  type="url"
                  className="form-control"
                  placeholder="Twitter"
                  name="twitterURL"
                  value={formData.twitterURL}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <input
                  type="url"
                  className="form-control"
                  placeholder="LinkedIn"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="6"
                  placeholder="Enter Bio in (max 100 words)."
                  name="bio"
                  value={formData.bio}
                  readOnly={read}
                  onChange={handleChange}
                />
              </div>
              {role === "superadmin" && (
                <>
                  <div className="form-group">
                    <label htmlFor="">Old Password</label>
                    <input
                      // type="password"
                      type={!showPassword ? "password" : "text"}
                      className="form-control form-password-feild"
                      placeholder="Old Password"
                      name="password"
                      value={oldPassword}
                      onChange={handleOldPasswordChange}
                    />
                    {showPassword ? (
                      <BiShow
                        className="hide-show-btn"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <BiHide
                        className="hide-show-btn"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                    {/* {passwordError && (
                      <span className="text-danger fs-8">{passwordError}</span>
                    )} */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">New Password</label>
                    <input
                      type={!showNewPassword ? "password" : "text"}
                      // type="password"
                      className="form-control form-password-feild"
                      placeholder="New Password"
                      name="password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                    {showNewPassword ? (
                      <BiShow
                        className="hide-show-btn"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    ) : (
                      <BiHide
                        className="hide-show-btn"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    )}
                    {passwordError && (
                      <span className="text-danger fs-8">{passwordError}</span>
                    )}
                  </div>
                </>
              )}

              <button
                onClick={handleUpdate}
                type="button"
                className="btn btn-light"
              >
                Update Profile
              </button>
            </div>
            <div className="">
              <button
                onClick={() => setRead(!read)}
                type="button"
                className="btn btn-light"
              >
                <BiSolidEditAlt />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

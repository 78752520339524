import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getSubCategory } from "../../../store/Action/subCategoryAction";
import {
  postNewsAdd,
  postNewsUpdate,
  getNewsId,
} from "../../../store/Action/newsAction";
import { getCategory } from "../../../store/Action/categoryAction";
// import { getUserList } from "../../../store/Action/usersAction";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import { getSecondarySubCategoryByParent } from "../../../store/Action/secondarySubCategoryAction";
import { getNextSecondarySubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
import { getNewSubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
import { FaRegCheckCircle } from "react-icons/fa";
import PreviewModal from "../../PreviewModal";
import "./news.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Editor from "../../../DecoupledEditor";
import { toast } from "react-toastify";
// import { getDataAPI } from "../../../services/apiWrapper";
import axios from "axios";
import moment from "moment";
import { setPreviewContent } from "../../../store/Action/previewAction";
import EditorComponent from "../../../Coeditorx.editor";
// import PreventTwoFingerSwipe from '../../../PreventTwoFingerSwipe';

function handleWheel(event) {
  console.log(event, "event");
  // Check if deltaY exists and its value is not 0
  if (event.deltaX !== 0) {
    // Prevent default scrolling behavior for vertical scrolling
    event.preventDefault();
  }
}
export default function AddNews() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [data, setData] = useState("Use Me");
  const [category, setCategory] = useState("");
  // const [latestName, setLatestName] = useState(false);
  const [topNews, setTopNews] = useState(false);
  const [popularNews, setPopularNews] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [updatedDate, setUpdatedDate] = useState("");
  const [heading, setHeading] = useState("");
  const [url, setUrl] = useState("");
  const [urlType, setUrlType] = useState("");
  const [oldPubDate, setOldPubDate] = useState("");
  const [author, setAuthor] = useState("");
  const [extraAuthor, setExtraAuthor] = useState("");
  const [date, setDate] = useState("");
  const [publishDate, setPublishDate] = useState(new Date());
  const [adminRole, setAdminRole] = useState("");
  const [description, setDescription] = useState("");
  const [imgCap, setImgCap] = useState("");
  const [imgAlt, setImgAlt] = useState("");
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [s3ImageId, setS3ImageId] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);
  const [error, setError] = useState(false);
  const { subCategoryData } = useSelector((state) => state);
  const { secondarySubCategoryData } = useSelector((state) => state);
  const [parentCategory, setParentCategory] = useState("");
  const [year, setYear] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [subParentCategory, setSubParentCategory] = useState("");
  const [nextCategory, setNextCategory] = useState({ _id: "", name: "" });
  const [newSubCategoryState, setNewSubCategoryState] = useState({
    _id: "",
    name: "",
  });
  const [constructedUrl, setConstructedUrl] = useState("");

  const [newsId, setNewsId] = useState("");

  const [userList, setUserList] = useState([]);
  console.log(userList, "userList");

  const { subNextCategoryReducer, newSubCategory } = useSelector(
    (state) => state
  );

  const handlePreview = () => {
    let payload = {
      author: author,

      heading: heading,
      publishDate: publishDate,
      url: url,
      urlType: urlType || "newurl",
      description: description,
      categoryid: parentCategory,
      subCategoryid: subParentCategory,
      year: year,
      imgCaption: imgCap,
      isDraft: isDraft || false,
      imgAlt: imgAlt,
      popularPost: popularNews || false,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
      seoKeywords: seoKeywords,
      secondDisplayNameId: nextCategory._id || "",
      nextSecondDisplayNameId: newSubCategoryState._id || "",
      topNews: topNews,
      country: selectedCountry,
      city: selectedCity,
      state: selectedState,
      content: data,
      videourl: videoUrl,
      // bannerimage: selectedBannerImageFile,
    };

    if (selectedBannerImageFile || s3ImageId) {
      payload.bannerimage = selectedBannerImageFile || s3ImageId;
    }
    dispatch(setPreviewContent(payload));
    console.log(payload, "payload");
    // navigate("/preview");
    setPreviewOpen(true);
    // window.open("/preview", "_blank");
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const filePicker = useRef(null); // Define the filePicker ref

  const dispatch = useDispatch();
  const today = moment().format("YYYY-MM-DDTHH:mm");
  // const today = moment().format('DD-MM-YYYYTHH:mm');
  console.log(today, "today");
  // const {subCategoryData} =useSelector((state)=>state);
  const { categoryData } = useSelector((state) => state);
  const id = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setEditorLoaded(true);
    // setData('')
    console.log(editorLoaded, "load");
    dispatch(getCategory());
  }, []);

  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  // Author List Fetching
  useEffect(() => {
    const mytoken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");
    setAdminRole(role);
    setAuthor(userId);
    setExtraAuthor("");

    const headers = { Authorization: `Bearer ${mytoken}` };
    axios.get(`${apiUrl}user/userlist`, { headers }).then((response) => {
      setUserList(response.data.data);
      console.log(response.data.data, " : response.data.data");
      setTimeout(() => {
        console.log(userList, " : UserList");
      }, 10);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
  };
  console.log(urlType, "12345");
  const handleUrlTypeChange = (selectUrlType) => {
    // console.log(urlType,"12345dfddff")
    console.log(selectUrlType, "12345");
    setUrlType(selectUrlType);
    // console.log(urlType,"12345")
  };
  console.log(urlType, "123457888");

  const handleOldPubDateChange = (event) => {
    setOldPubDate(event.target.value);
  };
  const handleAuthorChange = (author) => {
    setAuthor(author);
  };
  const handleExtraAuthorChange = (author) => {
    setExtraAuthor(author);
  };
  const handleTopNewsChange = (selectedTopNewsBoolean) => {
    setTopNews(selectedTopNewsBoolean);
  };
  const handlePopularNewsChange = (selectedTopNewsBoolean) => {
    setPopularNews(selectedTopNewsBoolean);
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleImageChange = (event) => {
    setImageUrl(event.target.value);
  };
  const handleVideoChange = (event) => {
    setVideoUrl(event.target.value);
  };
  const handleCaptionChange = (event) => {
    setImgCap(event.target.value);
  };
  const handleSeoTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };
  const handleSeoDescriptionChange = (event) => {
    setSeoDescription(event.target.value);
  };
  const handleSeoKeywordsChange = (event) => {
    setSeoKeywords(event.target.value);
  };
  const handleAltImgChange = (event) => {
    setImgAlt(event.target.value);
  };
  const handleYearChange = (event) => {
    const inputYear = event.target.value;

    if (/^\d{0,4}$/.test(inputYear)) {
      // Check if the entered value is a valid number and has at most 4 digits
      setYear(inputYear);
      setError(false);
    } else {
      // If the entered value is not a valid 4-digit number
      setYear("");
      setError(true);
    }
  };

  const handleHeadingChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 180) {
      setHeading(inputValue);
    } else {
      // You can handle the case where the input exceeds the maximum character limit, such as showing an error message.
      toast.error("Input exceeds the maximum character limit (180)");
    }
  };
  const handleUrlChange = (event) => {
    const currentDate = new Date();
    if (updatedDate) {
      const updatedDateObj = new Date(updatedDate); // Convert the updatedDate to a Date object
      var timeDifference = (currentDate - updatedDateObj) / 1000 / 60; // Calculate the difference in minutes
    }

    if (id.id && !isDraft && updatedDate && timeDifference > 15) {
      toast.error(
        "You are not allowed to update the URL, kindly contact developer with your Updated Url."
      );
    } else {
      let value = event.target.value;

      // Trim leading and trailing spaces
      value = value.trim();

      // Replace multiple spaces with a single space
      value = value.replace(/\s+/g, " ");

      // Remove special characters except hyphen
      value = value.replace(/[^a-zA-Z0-9- ]/g, "");

      // Replace spaces with hyphens
      value = value.replace(/\s+/g, "-");
      // Replace consecutive hyphens with a single hyphen
      value = value.replace(/-+/g, "-");
      // Convert to lowercase
      value = value.toLowerCase();

      console.log(value, "url");

      // Update the state with the sanitized value
      setUrl(value);
    }
  };

  const removeTrailingHypen = (value) => {
    // Trim leading and trailing hyphens
    let value2 = value.replace(/^-+|-+$/g, "");
    return value2;
  };
  // const handleUrlChange = (event) => {
  //   let value = event.target.value;

  //   // Remove special characters except hyphen
  //   value = value.replace(/[^a-zA-Z0-9- ]/g, "");

  //   // Replace spaces with hyphens
  //   value = value.replace(/\s+/g, "-");

  //   // Convert to lowercase
  //   value = value.toLowerCase();
  //   console.log(value, "url");
  //   // Update the state with the sanitized value
  //   setUrl(value);
  // };
  // const handleDateChange = (event) => {
  //   const selectedDate = event.target.value;

  //   // const fromattedData = moment(selectedDate).format('DD-MM-YYYYTHH:mm').toISOString();
  //   const fromattedData = moment(selectedDate, 'DD-MM-YYYYTHH:mm').toISOString();
  //   if (fromattedData) {
  //     setDate(fromattedData);
  //   }

  //   console.log(date, "datechange", fromattedData);
  // };
  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate, "YYYY-MM-DDTHH:mm").toDate();

    if (formattedDate) {
      setPublishDate(formattedDate);
    }

    console.log(publishDate, "datechange");
  };

  console.log(date, "datechange1");
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleNextCategoryChange = (selectedCategoryId) => {
    console.log(selectedCategoryId, "trytr");
    // Find the selected category object based on the ID
    const selectedCategory = subNextCategoryReducer.data.find(
      (item) => item.secondDisplayName._id === selectedCategoryId
    );

    // Update the state with the selected category object
    setNextCategory({
      _id: selectedCategoryId,
      name: selectedCategory ? selectedCategory.secondDisplayName.name : "",
    });
    console.log(nextCategory, "ttt");
  };

  const handleNewSubCategoryChange = (selectedCategoryId) => {
    console.log(selectedCategoryId, "newsubcategory");
    console.log("newSubCategory.data:", newSubCategory.data);
    const selectedCategory = newSubCategory?.data?.find(
      (item) => item.nextSecondDisplayName._id === selectedCategoryId
    );
    console.log("Selected Category:", selectedCategory);
    // Update the state with the selected category object
    setNewSubCategoryState(
      (prevState) => ({
        ...prevState,
        _id: selectedCategoryId,
        name: selectedCategory
          ? selectedCategory.nextSecondDisplayName?.name
          : "",
      }),
      () => {
        console.log(newSubCategoryState, "hola amigo");
      }
    );
  };
  const onBannerImagePicked = (event) => {
    const fileInput = filePicker.current; // Access the filePicker ref
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)
    const requiredAspectRatio = 16 / 9; // Aspect ratio of 16:9

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        setBannerImagePreview("");
        setBannerFileSizeError(true);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            if (Math.abs(aspectRatio - requiredAspectRatio) > 0.01) {
              // Allowing a small tolerance
              alert("Image aspect ratio should be 16:9.");
              fileInput.value = ""; // Clear the input field
              setBannerImagePreview("");
              setBannerFileSizeError(true);
            } else {
              setSelectedBannerImageFile(file);
              setBannerImagePreview(reader.result);
              setBannerFileSizeError(false);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
  };
  // const handleDraft = () => {
  //   setIsDraft(!isDraft);
  // };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    // Dispatch the action with the form data
    console.log(urlType, "1234url");
    const cleanedContent = replaceNbsp(data);

    if (!id.id) {
      let payload = {
        author: author,
        frontUrl: constructedUrl,
        // extraAuthor: extraAuthor && extraAuthor,
        // title: title,
        // title: title,
        heading: heading,
        publishDate: publishDate,
        url: removeTrailingHypen(url),
        urlType: urlType || "newurl",
        description: description,
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        year: year,
        imgCaption: imgCap,
        isDraft: false,
        imgAlt: imgAlt,
        popularPost: popularNews || false,
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        // secondarySubCategoryId: nextCategory._id,
        secondDisplayNameId: nextCategory._id || "",
        nextSecondDisplayNameId: newSubCategoryState._id || "",
        topNews: topNews,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
        // image: imageUrl,
        content: cleanedContent,
        videourl: videoUrl,
      };

      if (urlType === "oldurl") {
        payload.oldPubDate = oldPubDate;
      } else {
        payload.oldPubDate = "";
      }

      if (selectedBannerImageFile) {
        payload.bannerimage = selectedBannerImageFile;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }
      if (extraAuthor) {
        payload.extraAuthor = extraAuthor;
      }
      const response = await dispatch(postNewsAdd(payload));
      if (response) {
        // author: author,
        setOldPubDate("");
        setCategory("");
        setSelectedCity("");
        setParentCategory("");
        setSubParentCategory("");
        setContent("");
        setDescription("");
        setHeading("");
        setUrl("");
        setUrlType("");
        setImageUrl("");
        setS3ImageId("");
        setSelectedBannerImageFile(null);
        setVideoUrl("");
        setTitle("");
        setExtraAuthor("");
        setSelectedCountry("");
        setNewSubCategoryState({ _id: "", name: "" });
        setNextCategory({ _id: "", name: "" });
        setSelectedState("");
        setData("");
        navigate("/managenews");
        // toast.sucess('')
      }
    } else {
      let payload = {
        author: author,
        frontUrl: constructedUrl,
        // extraAuthor: extraAuthor && extraAuthor,
        // title: title,
        // title: title,
        heading: heading,
        publishDate: publishDate,
        url: removeTrailingHypen(url),
        year: year,
        urlType: urlType,
        description: description,
        isDraft: false,
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        imgCaption: imgCap,
        imgAlt: imgAlt,
        popularPost: popularNews || false,
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        // secondarySubCategoryId: nextCategory._id,
        secondDisplayNameId: nextCategory._id || "",
        nextSecondDisplayNameId: newSubCategoryState._id || "",
        topNews: topNews,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
        // image: imageUrl,
        content: cleanedContent,
        videourl: videoUrl,
      };

      if (urlType === "oldurl") {
        payload.oldPubDate = oldPubDate;
      } else {
        payload.oldPubDate = "";
      }

      if (selectedBannerImageFile) {
        payload.bannerimage = selectedBannerImageFile;
      }
      if (extraAuthor) {
        payload.extraAuthor = extraAuthor;
      }
      await dispatch(postNewsUpdate(id.id, payload));
      navigate("/managenews");
    }
  };
  //replace funtion for ckeditor
  const replaceNbsp = (str) => {
    return str ? str.replace(/&nbsp;/g, " ") : str;
  };
  const handleDraftSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    // Dispatch the action with the form data
    console.log(urlType, "1234url");
    const cleanedContent = replaceNbsp(data);

    if (!id.id && !newsId) {
      let payload = {
        author: author,
        frontUrl: constructedUrl,
        // extraAuthor: extraAuthor && extraAuthor,
        isDraft: true,
        heading: heading,
        publishDate: publishDate,
        url: url,
        urlType: urlType || "newurl",
        description: description,
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        year: year,
        imgCaption: imgCap,
        // isDraft: isDraft || false,
        imgAlt: imgAlt,
        popularPost: popularNews || false,
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        // secondarySubCategoryId: nextCategory._id,
        secondDisplayNameId: nextCategory._id || "",
        nextSecondDisplayNameId: newSubCategoryState._id || "",
        topNews: topNews,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
        // image: imageUrl,
        content: cleanedContent,
        videourl: videoUrl,
      };

      if (urlType === "oldurl") {
        payload.oldPubDate = oldPubDate;
      } else {
        payload.oldPubDate = "";
      }

      if (selectedBannerImageFile) {
        payload.bannerimage = selectedBannerImageFile;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }
      if (extraAuthor) {
        payload.extraAuthor = extraAuthor;
      }
      const response = await dispatch(postNewsAdd(payload));
      if (response) {
        console.log(response);
        setNewsId(response.data?._id);
        // author: author,
        // setOldPubDate("");
        // setCategory("");
        // setSelectedCity("");
        // setParentCategory("");
        // setSubParentCategory("");
        // setContent("");
        // setDescription("");
        // setHeading("");
        // setUrl("");
        // setUrlType("");
        // setImageUrl("");
        // setS3ImageId("");
        // setSelectedBannerImageFile(null);
        // setVideoUrl("");
        // setTitle("");
        // setSelectedCountry("");
        // setNewSubCategoryState({ _id: "", name: "" });
        // setNextCategory({ _id: "", name: "" });
        // setSelectedState("");
        // setData("");
        // navigate("/managenews");
        // toast.sucess('')
      }
    } else if (newsId) {
      let payload = {
        author: author,
        frontUrl: constructedUrl,
        // extraAuthor: extraAuthor && extraAuthor,
        // title: title,
        // title: title,
        heading: heading,
        publishDate: publishDate,
        url: url,
        year: year,
        urlType: urlType,
        description: description,
        isDraft: true,
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        imgCaption: imgCap,
        imgAlt: imgAlt,
        popularPost: popularNews || false,
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        // secondarySubCategoryId: nextCategory._id,
        secondDisplayNameId: nextCategory._id || "",
        nextSecondDisplayNameId: newSubCategoryState._id || "",
        topNews: topNews,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
        // image: imageUrl,
        content: cleanedContent,
        videourl: videoUrl,
      };

      if (urlType === "oldurl") {
        payload.oldPubDate = oldPubDate;
      } else {
        payload.oldPubDate = "";
      }

      if (selectedBannerImageFile) {
        payload.bannerimage = selectedBannerImageFile;
      }
      if (extraAuthor) {
        payload.extraAuthor = extraAuthor;
      }
      await dispatch(postNewsUpdate(newsId, payload));
      // navigate("/managenews");
    } else {
      let payload = {
        author: author,
        frontUrl: constructedUrl,
        // extraAuthor: extraAuthor && extraAuthor,
        // title: title,
        // title: title,
        heading: heading,
        publishDate: publishDate,
        url: url,
        year: year,
        urlType: urlType,
        description: description,
        isDraft: true,
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        imgCaption: imgCap,
        imgAlt: imgAlt,
        popularPost: popularNews || false,
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        seoKeywords: seoKeywords,
        // secondarySubCategoryId: nextCategory._id,
        secondDisplayNameId: nextCategory._id || "",
        nextSecondDisplayNameId: newSubCategoryState._id || "",
        topNews: topNews,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
        // image: imageUrl,
        content: cleanedContent,
        videourl: videoUrl,
      };

      if (urlType === "oldurl") {
        payload.oldPubDate = oldPubDate;
      } else {
        payload.oldPubDate = "";
      }

      if (selectedBannerImageFile) {
        payload.bannerimage = selectedBannerImageFile;
      }
      if (extraAuthor) {
        payload.extraAuthor = extraAuthor;
      }
      await dispatch(postNewsUpdate(id.id, payload));
      // navigate("/managenews");
    }
  };
  useEffect(() => {
    // Fetch the sub-categories based on the selected parent category
    if (parentCategory) {
      dispatch(getSubCategoryByParent(parentCategory));
    }
  }, [parentCategory, dispatch]);

  console.log(subCategoryData, secondarySubCategoryData, "dddd");
  console.log(data, "dataaaaa");

  useEffect(() => {
    // Fetch the secondary sub-categories based on the selected parent category and sub-parent category
    if (subParentCategory) {
      dispatch(getNextSecondarySubCategoryByParent(subParentCategory));
    }
  }, [subParentCategory, dispatch]);

  useEffect(() => {
    // Fetch the secondary sub-categories based on the selected parent category and sub-parent category
    if (nextCategory?._id) {
      // console.log(nextCategory?._id,"inuseeffect")
      dispatch(getNewSubCategoryByParent(nextCategory?._id));
    }
  }, [nextCategory?._id, dispatch]);

  // const handleParentCategoryChange = (e) => {
  //   setParentCategory(selectedParentCategory);
  // };
  const handleParentCategoryChange = (e) => {
    setParentCategory(e.target.value);
    setSubParentCategory("");
  };
  const handleSubParentCategoryChange = (e) => {
    setSubParentCategory(e.target.value);
    setNextCategory("");
  };

  // const onBannerImagePicked = (event) => {
  //   const fileInput = filePicker.current; // Access the filePicker ref
  //   const file = fileInput.files[0];
  //   const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

  //   if (file) {
  //     const fileSizeInKB = file.size / 1024;

  //     if (fileSizeInKB > maxSizeInKB) {
  //       // File size exceeds the allowed limit
  //       alert("Logo image size exceeds the maximum allowed size.");
  //       fileInput.value = ""; // Clear the input field
  //       setBannerImagePreview("");
  //       setBannerFileSizeError(true);
  //     } else {
  //       setSelectedBannerImageFile(file);
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         setBannerImagePreview(reader.result);
  //         setBannerFileSizeError(false);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // };

  useEffect(
    (i) => {
      const fetchNews = async () => {
        try {
          // if(id.id){
          const resp = await dispatch(getNewsId(id.id));
          console.log(resp, "updateNEws");
          if (resp) {
            setCategory(
              resp.secondarySubCategoryId ? resp.secondarySubCategoryId : ""
            );
            setParentCategory(resp.categoryid?._id ? resp.categoryid?._id : "");
            setSubParentCategory(
              resp.subCategoryid?._id ? resp.subCategoryid?._id : ""
            );
            setNewSubCategoryState({
              _id: resp?.nextSecondDisplayNameId
                ? resp?.nextSecondDisplayNameId
                : "",
              // _id: resp?.nextSecondDisplayName
              //   ? resp?.nextSecondDisplayName?._id
              //   : "",
              // name: resp?.nextSecondDisplayName
              //   ? resp?.nextSecondDisplayName?.name
              //   : "",
            });
            setNextCategory({
              _id: resp?.secondDisplayNameId ? resp?.secondDisplayNameId : "",

              // _id: resp?.secondDisplayName ? resp?.secondDisplayName?._id : "",
              // name: resp?.secondDisplayName
              //   ? resp?.secondDisplayName?.name
              //   : "",
            });
            //     author: author,
            // oldPubDate: oldPubDate,
            if (resp.author) {
              setAuthor(resp?.author._id);
            }
            if (resp.extraAuthor) {
              setAuthor(resp?.extraAuthor._id);
            }
            if (resp.oldPubDate) {
              setOldPubDate(resp?.oldPubDate);
            }
            setImgAlt(resp.imgAlt ? resp.imgAlt : "");
            setImgCap(resp.imgCaption ? resp.imgCaption : "");
            setPopularNews(resp.popularPost ? resp.popularPost : false);
            setTopNews(resp.topNews ? resp.topNews : false);
            setSeoTitle(resp.seoTitle ? resp.seoTitle : "");
            setIsDraft(resp.isDraft ? resp.isDraft : "");
            setUpdatedDate(resp.updatedAt ? resp.updatedAt : "");
            setSeoDescription(resp.seoDescription ? resp.seoDescription : "");
            setSeoKeywords(resp.seoKeywords ? resp.seoKeywords : "");
            setSelectedCity(resp?.city);
            setContent(resp?.content);
            setDescription(resp?.description);
            setHeading(resp?.heading);
            setUrl(resp?.url);
            setUrlType(resp?.urlType);
            setImageUrl(resp?.image);
            setS3ImageId(resp?.bannerimage);
            setVideoUrl(resp?.videourl);
            setTitle(resp?.title);
            setSelectedCountry(resp?.country);
            setSelectedState(resp?.state);
            setPublishDate(resp?.publishDate ? resp?.publishDate : "");
            setData(resp?.content);
            setYear(resp?.year ? resp?.year : "");
            // setInputValue(resp.data.data.displayName);
            // setSelectedStatus(resp.data.data.status);
          }
        } catch (error) {
          // Handle error, e.g., show an error toast message
        }
      };
      if (id.id) {
        // setData('');

        fetchNews();
      } else {
        setOldPubDate("");
        setCategory("");
        setSelectedCity("");
        setParentCategory("");
        setSubParentCategory("");
        setContent("");
        setDescription("");
        setHeading("");
        setUrl("");
        setUrlType("");
        setImageUrl("");
        setS3ImageId("");
        setSelectedBannerImageFile(null);
        setVideoUrl("");
        setTitle("");
        setSelectedCountry("");
        setNewSubCategoryState({ _id: "", name: "" });
        setNextCategory({ _id: "", name: "" });
        setSelectedState("");
        setData("");
        setData("");
        setEditorLoaded(true);
        setPopularNews(false);
        setSeoTitle("");
        setSeoDescription("");
        setSeoKeywords("");
        console.log("id is not find");
      }
    },
    [id]
  );
  console.log(newSubCategory, nextCategory._id, "kuchtohai");
  console.log(selectedBannerImageFile, "selectedBannerImageFile");

  useEffect(() => {
    // Add event listener with { passive: false } option
    window.addEventListener("wheel", handleWheel, { passive: false });

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // const constructedUrl = parentCategory&&url
  // ? `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${url}`
  // : '';
  // const constructedUrls = parentCategory&&subParentCategory&&url
  // ? `https://educationpost.in/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${subParentCategory?.data?.find(item => item._id === subParentCategory)?.displayName}/${url}`
  // : '';
  // let constructedUrl = '';

  // if (parentCategory && url) {
  //   if (subParentCategory) {
  //     if(nextCategory?._id){
  //       constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${subCategoryData?.data?.subcategories?.find(item => item._id === subParentCategory)?.displayName}/${subNextCategoryReducer?.data?.subcategories?.find(item => item._id === nextCategory?._id)?.displayName}/${url}}`;
  //     }else{
  //     constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${subCategoryData?.data?.subcategories?.find(item => item._id === subParentCategory)?.displayName}/${url}`;
  //   } }else {
  //     constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${url}`;
  //   }
  // }
  // console.log(subNextCategoryReducer,subCategoryData,categoryData,'subNextCategoryReducer')
  // if (parentCategory && url) {
  //   if (subParentCategory) {
  //     if (nextCategory?._id) {
  //     //   constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.displayName}/${subCategoryData?.data?.subcategories?.find(item => item._id === subParentCategory)?.displayName}/${subNextCategoryReducer?.data
  //     //   ?.find(item => item._id === nextCategory?._id)?.seoUrl
  //     // }/${url}`;
  //     constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.seoUrl}/${subCategoryData?.data?.subcategories?.find(item => item._id === subParentCategory)?.seoUrlSubCategory}/${subNextCategoryReducer?.data?.find(item => item?.secondDisplayName?._id === nextCategory?._id)?.secondDisplayName?.seoUrl || 'undefined'}/${url}`;

  //     } else {
  //       constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.seoUrl}/${subCategoryData?.data?.subcategories?.find(item => item._id === subParentCategory)?.seoUrlSubCategory}/${url}`;
  //     }
  //   } else {
  //     constructedUrl = `https://educationpost.in/news/${categoryData?.data?.find(item => item._id === parentCategory)?.seoUrl}/${url}`;
  //   }
  // }
  // console.log(subCategoryData,subCategoryData)

  useEffect(() => {
    if (parentCategory && url && subParentCategory && nextCategory?._id) {
      const parentCategoryUrl =
        categoryData?.data?.find((item) => item._id === parentCategory)
          ?.seoUrl || "undefined";
      const subCategoryUrl =
        subCategoryData?.data?.subcategories?.find(
          (item) => item._id === subParentCategory
        )?.seoUrlSubCategory || "undefined";
      const nextCategoryUrl =
        subNextCategoryReducer?.data?.find(
          (item) => item?.secondDisplayName?._id === nextCategory?._id
        )?.secondDisplayName?.seoUrl || "undefined";

      setConstructedUrl(
        `https://educationpost.in/news/${parentCategoryUrl}/${subCategoryUrl}/${nextCategoryUrl}/${url}`
      );
    } else if (parentCategory && url && subParentCategory) {
      const parentCategoryUrl =
        categoryData?.data?.find((item) => item._id === parentCategory)
          ?.seoUrl || "undefined";
      const subCategoryUrl =
        subCategoryData?.data?.subcategories?.find(
          (item) => item._id === subParentCategory
        )?.seoUrlSubCategory || "undefined";

      setConstructedUrl(
        `https://educationpost.in/news/${parentCategoryUrl}/${subCategoryUrl}/${url}`
      );
    } else if (parentCategory && url) {
      const parentCategoryUrl =
        categoryData?.data?.find((item) => item._id === parentCategory)
          ?.seoUrl || "undefined";

      setConstructedUrl(
        `https://educationpost.in/news/${parentCategoryUrl}/${url}`
      );
    }
  }, [
    parentCategory,
    url,
    subParentCategory,
    nextCategory,
    subNextCategoryReducer,
    categoryData,
    subCategoryData,
  ]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(constructedUrl)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Could not copy URL to clipboard: ", error);
      });
  };

  console.log(extraAuthor, "vvvvvvv22");

  return (
    <div>
      <div className="addnews">
        <div className="line"></div>
        <div className="card ">
          {/* <div className="card "> */}
          <p>{constructedUrl}</p>
          <button
            className="btn btn-outline-secondary"
            style={{
              maxWidth: "fit-content",
              marginBottom: "15px",
              marginLeft: "auto",
              marginRight: "17px",
            }}
            onClick={copyToClipboard}
          >
            <i class="fa-regular fa-copy p-1"></i>Copy URL
          </button>
          {/* </div> */}
          <div className="card-header">{id.id ? "Edit News" : "Add News"}</div>
          <div className="card-body addnews-container">
            <form>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Select Category <span className="req-feild">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={parentCategory}
                  onChange={(e) => {
                    // Update parentCategory state
                    handleParentCategoryChange(e);
                  }}
                >
                  <option value="">Select Category</option>
                  {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))}
                </select>
              </div>

              {parentCategory && (
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select Sub Category
                    {/* <span className="req-feild">*</span> */}
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={subParentCategory}
                    onChange={(event) => {
                      // handleCategoryChange(event.target.value);
                      handleSubParentCategoryChange(event);
                    }}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategoryData &&
                      subCategoryData.data.subcategories?.map((item) => (
                        <>
                          <option key={item._id} value={item._id}>
                            {item.displayName}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              )}

              {subParentCategory && (
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select Secondary Sub Category
                  </label>
                  {/* <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={category}
                  onChange={(event) => {
                    handleCategoryChange(event.target.value);
                  }}
                >
                  <option value="">Select Secondary Sub Category</option>
                  {secondarySubCategoryData &&
                    secondarySubCategoryData.data?.map((item) => (
                      <>
                        <option key={item._id} value={item._id}>
                          {item.secondDisplayName?.name}
                        </option>
                      </>
                    ))}
                </select> */}
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    // value={nextCategory?.secondDisplayName?._id}
                    value={nextCategory?._id}
                    onChange={(event) => {
                      handleNextCategoryChange(event.target.value);
                    }}
                  >
                    {" "}
                    <option value="">Select Secondary Sub Category</option>
                    {subNextCategoryReducer &&
                      subNextCategoryReducer.data?.map((item) => (
                        <>
                          <option
                            key={item?.secondDisplayName?._id}
                            value={item?.secondDisplayName?._id}
                          >
                            {item?.secondDisplayName?.name || "N/A"}
                          </option>{" "}
                        </>
                      ))}
                    {/* <option value="">Select Secondary Sub Category</option>
  {subNextCategoryReducer &&
    subNextCategoryReducer.data?.map((item) => (
      <option
        key={item.secondDisplayName._id}
        value={item.secondDisplayName._id}
      >
        {item.secondDisplayName.name}
      </option>
    ))} */}
                  </select>
                </div>
              )}

              {nextCategory?._id && (
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select New Sub Category
                    {/* <span className="req-feild">*</span> */}
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    // value={newSubCategoryState.nextSecondDisplayName?._id}
                    value={newSubCategoryState?._id}
                    onChange={(event) => {
                      handleNewSubCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select New Sub Category</option>
                    {newSubCategory &&
                      newSubCategory.data?.map(
                        (item) =>
                          // Check if item.nextSecondDisplayName is not an empty object and has a non-empty name
                          item.nextSecondDisplayName &&
                          Object.keys(item.nextSecondDisplayName).length > 0 &&
                          item.nextSecondDisplayName.name !== "" && (
                            <option
                              key={item._id}
                              value={item.nextSecondDisplayName?._id}
                            >
                              {item.nextSecondDisplayName?.name}
                            </option>
                          )
                      )}
                  </select>
                </div>
              )}

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Is it Popular News
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={popularNews}
                  onChange={(event) =>
                    handlePopularNewsChange(event.target.value)
                  }
                >
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                  {/* {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))} */}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Is it Top News
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={topNews}
                  onChange={(event) => handleTopNewsChange(event.target.value)}
                >
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                  {/* {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))} */}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo title"
                  value={seoTitle}
                  onChange={handleSeoTitleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo description"
                  value={seoDescription}
                  onChange={handleSeoDescriptionChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Keywords</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo seokeywords"
                  value={seoKeywords}
                  onChange={handleSeoKeywordsChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Select Country
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  <option>india</option>
                  <option>us</option>
                </select>
              </div>

              {selectedCountry && (
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Select State
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    <option>india</option>
                    <option>us</option>
                  </select>
                </div>
              )}
              {selectedState && (
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Select City</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    <option value="" disabled>
                      Select City
                    </option>
                    <option>India</option>
                    <option>Dubai</option>
                    <option>China</option>
                  </select>
                </div>
              )}

              <div className="form-group">
                <label htmlFor="exampleText1">Year</label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Year"
                  value={year}
                  onChange={handleYearChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Video URL</label>
                <input
                  type="url"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="www.falana.com"
                  value={videoUrl}
                  onChange={handleVideoChange}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="exampleText1">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="title"
                  value={title}
                  onChange={handleTitleChange}
                />
              </div> */}

              <div className="form-group">
                <label htmlFor="exampleText1">
                  URL<span className="req-feild">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Url"
                  value={url}
                  onChange={handleUrlChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">
                  What is the URL type
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={urlType}
                  onChange={(event) => {
                    handleUrlTypeChange(event.target.value);
                  }}
                >
                  <option value="newurl">New Url</option>
                  <option value="oldurl">Old Url</option>
                  {/* {secondarySubCategoryData &&
                    secondarySubCategoryData.data?.map((item) => (
                      <>
                        <option key={item._id} value={item._id}>
                          {item.secondDisplayName}
                        </option>
                      </>
                    ))} */}
                </select>
              </div>
              {/* date  */}
              {/* {urlType === "oldurl" && (
                <div className="form-group">
                  <label htmlFor="oldPubDate">Old Publisher Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="oldPubDate"
                    aria-describedby="emailHelp"
                    placeholder="dd/mm/yyyy"
                    value={oldPubDate}
                    onChange={handleOldPubDateChange}
                  />
                </div>
              )} */}

              {/* Old Date Pick Ends here  */}

              {/* Writer Pick  */}
              {adminRole === "superadmin" && (
                <div className="form-group">
                  <label htmlFor="writerPick">
                    Select/Change the Author{" "}
                    <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="writerPick"
                    value={author}
                    onChange={(event) => {
                      handleAuthorChange(event.target.value);
                    }}
                  >
                    {/* <option value="newurl">New Url</option>
                  <option value="oldurl">Old Url</option> */}
                    {userList.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name ? item.name : item.email}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {adminRole === "superadmin" && (
                <div className="form-group">
                  <label htmlFor="writerPick">Select Extra the Author</label>
                  <select
                    className="form-control"
                    id="writerPick2"
                    value={extraAuthor}
                    onChange={(event) => {
                      handleExtraAuthorChange(event.target.value);
                      console.log(extraAuthor, "vvvvvvv");
                    }}
                  >
                    {/* <option value="newurl">New Url</option>
                  <option value="oldurl">Old Url</option> */}
                    <option value="">Select Extra Author</option>
                    {userList.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name ? item.name : item.email}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {/* Writer Pick ends here  */}

              <div className="form-group">
                <label htmlFor="exampleText1">
                  Heading <span className="req-feild">*</span>
                  <small>(Max 180 character)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Heading"
                  value={heading}
                  onChange={handleHeadingChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Sub Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="exampleText1">Image URL</label>
                <input
                  type="url"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="www.falana.com"
                  value={imageUrl}
                  onChange={handleImageChange}
                />
              </div> */}
              {/* Image Picker Starts Here */}
              <div className="news-img-container">
                <div className="form-group ">
                  <label htmlFor="exampleText1">Upload Image</label>
                  <div className="add-img">
                    <button
                      type="button"
                      onClick={() => filePicker.current.click()} // Access the filePicker ref
                    >
                      + Image
                    </button>
                    <input
                      type="file"
                      ref={filePicker}
                      onChange={onBannerImagePicked}
                    />
                    {bannerFileSizeError && (
                      <div className="error-message">
                        File size exceeds the maximum allowed size.
                      </div>
                    )}
                  </div>

                  {/* {(bannerImagePreview || s3ImageId) && (
                  <div className="image-preview">
                    <img src={bannerImagePreview ? bannerImagePreview : `https://api.educaionpost.in/s3-images/${s3ImageId}`} alt={'test alt name'} />
                  </div>
                )} */}
                  {bannerImagePreview || s3ImageId ? (
                    <div className="image-preview">
                      <img
                        src={
                          bannerImagePreview
                            ? bannerImagePreview
                            : `https://api.educaionpost.in/s3-images/${s3ImageId}`
                        }
                        alt="test alt name"
                      />
                    </div>
                  ) : null}
                </div>
                {(bannerImagePreview || s3ImageId) && (
                  <>
                    <div className="form-group">
                      <label htmlFor="exampleText1">Image Caption</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleText1"
                        aria-describedby="emailHelp"
                        placeholder="image caption"
                        value={imgCap}
                        onChange={handleCaptionChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleText1">Image Alt.</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleText1"
                        aria-describedby="emailHelp"
                        placeholder="image alt"
                        value={imgAlt}
                        onChange={handleAltImgChange}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* Image Picker Ends Here */}

              {/* <div className="form-group">
                <label htmlFor="exampleText1">Content</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Content"
                  value={content}
                  onChange={handleContentChange}
                />
              </div> */}
              {/* <Editor />
               */}

              {/* <div className="form-group">
                <label htmlFor="exampleText1">
                  Upload Date <span className="req-feild">*</span>
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  value={date}
                  onChange={handleDateChange}
                  max={today}
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="exampleText1">
                  Publish Date <span className="req-feild">*</span>
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  required
                  value={moment(publishDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={handleDateChange}
                  max={moment(publishDate).format("YYYY-MM-DDTHH:mm")}
                />
              </div>

              <div className="form-group ck-editor-container">
                <label htmlFor="exampleText1">
                  Content <span className="req-feild">*</span>
                </label>
                <Editor
                  name="description"
                  value={data}
                  onChange={(data) => {
                    setData(data)
                    // setData(data.replace(/&nbsp;/g, " "));
                    // console.log(data, "dataaaaa");
                  }}
                  editorLoaded={editorLoaded}
                />
              </div>
              {/* {JSON.stringify(data)} */}
            </form>

            {/* <div >
              <h4 style={{ color: "green" }}>New Feature Rich Editor WIP...</h4>
              <EditorComponent initialEditorData={data} /> 
            </div> */}

            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-lg my-3"
              style={{ backgroundColor: "#0d0d42e0", color: "white" }}
            >
              {id.id ? "Update News" : "Add News"}
            </button>

            {/* <button
              type="button"
              onClick={handleDraft}
              className="btn btn-light btn-lg my-3 ml-5"
              style={isDraft ? { background: "green", color: "white" } : {}}
            >
              {!isDraft ? "Add to Draft" : "Remove From Draft"}
              {isDraft && <FaRegCheckCircle className="ml-2" />}
            </button> */}
            <button
              type="button"
              onClick={handleDraftSubmit}
              className="btn  btn-lg my-3 ml-5"
              style={{ backgroundColor: "#0d0d42e0", color: "white" }}
            >
              Draft
            </button>
            <button
              className="btn btn-info btn-md my-3"
              style={{ float: "right" }}
              onClick={handlePreview}
            // to="/preview"
            // target="_blank"
            >
              Preview
            </button>
          </div>
          <PreviewModal
            isOpen={isPreviewOpen}
            handleClose={handleClosePreview}
            editorData={data}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function UserList() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const [userList, setUserList] = useState([]);
  useEffect(() => {
    const mytoken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");

    const headers = { Authorization: `Bearer ${mytoken}` };
    axios.get(`${apiUrl}user/userlist`, { headers }).then((response) => {
      setUserList(response.data.data);
      console.log(response.data.data, " : response.data.data");
      setTimeout(() => {
        console.log(userList, " : UserList");
      }, 10);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="line"></div>
      <div className="card">
        <div className="card-header">User List</div>

        <div className="card-body">
          <div className="user-list-container">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Role</th>
                  <th scope="col">Author</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((item, i) => (
                  <tr key={i}>
                    <td>{item.role}</td>
                    <td>
                      {item.name}
                      {/* <Link to={`/author-posts/${item._id}`}>{item.name}</Link> */}
                    </td>
                    <td>{item.email}</td>
                    <td>
                      <Link to={`/update-profile/${item._id}`}>
                        <button
                          className="action-button"
                          //  onClick={() => handleEdit(category)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

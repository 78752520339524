import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./magzine.css";

export default function MagazineSubscriberForm({ data }) {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [magazineSubDetails, setMagazineSubDetails] = useState([]);
  console.log(id, "id");
  const handleDetails = async (id) => {
    try {
      const response = await axios.get(
        `${apiUrl}subscriptions-with-magazines/user/${id}`
      );
      if (response) {
        // console.log(response, "setMagazineSubDetails");
        setMagazineSubDetails(
          response.data.subscriptions[response.data.subscriptions.length - 1]
        );
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  useEffect(() => {
    handleDetails(id);
  }, []);
  console.log(magazineSubDetails, "setMagazineSubDetails");

  return (
    <div style={{ padding: "20px" }} className="">
      <h2>{id}</h2>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>Field</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="magazine-table-heading">subscription Type</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              {magazineSubDetails?.subscriptionType}
            </td>
          </tr>
          {magazineSubDetails?.subscriptionPlan && (
            <tr>
              <td className="magazine-table-heading">subscription Plan</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {JSON.stringify(magazineSubDetails?.subscriptionPlan)}
              </td>
            </tr>
          )}
          {magazineSubDetails?.paymentDetails && (
            <tr>
              <td className="magazine-table-heading">payment Details</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {JSON.stringify(magazineSubDetails?.paymentDetails)}
              </td>
            </tr>
          )}
          <tr>
            <td className="magazine-table-heading">subscription Status</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              {magazineSubDetails?.subscriptionStatus}
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">magazines To Show</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              {magazineSubDetails?.magazinesToShow?.map((item, i) => (
                <span key={i}>"{item.issueDate}", </span>
              ))}
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">subscription Start Date</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
              officiis quo perspiciatis
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">subscription End Date</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
              officiis quo perspiciatis
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">State</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
              officiis quo perspiciatis
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">Country</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
              officiis quo perspiciatis
            </td>
          </tr>
          <tr>
            <td className="magazine-table-heading">Occupation</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
              officiis quo perspiciatis
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
